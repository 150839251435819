import React from 'react';
import { Input, Form, Radio, DatePicker, Card } from 'antd';
import moment from 'moment';
import './BasicForm.css';


class BasicForm extends React.Component {

    render() {
        const {
            values,
            form: { getFieldDecorator },
        } = this.props;
        return (
            <Form className="user-info-form" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                <Card title="个人信息" size="small" bordered={false}>
                    <Form.Item label="姓名">
                        {getFieldDecorator('name', {
                            initialValue: values.name,
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item label="性别">
                        {getFieldDecorator('gender', {
                            initialValue: values.gender,
                        })(
                            <Radio.Group>
                                <Radio value="男">男</Radio>
                                <Radio value="女">女</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>

                    <Form.Item label="生日">
                        {getFieldDecorator('birth', {
                            initialValue: moment(values.birth || '1990-01-01'),
                        })(
                            <DatePicker />
                        )}
                    </Form.Item>

                    <Form.Item label="身份证号">
                        {getFieldDecorator('idcard', {
                            initialValue: values.idcard,
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item label="所在省">
                        {getFieldDecorator('province', {
                            initialValue: values.province,
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item label="所在市">
                        {getFieldDecorator('city', {
                            initialValue: values.city,
                        })(
                            <Input />
                        )}
                    </Form.Item>
                </Card>

                <Card title="联系方式" size="small" bordered={false}>

                    <Form.Item label="电话">
                        {getFieldDecorator('tel', {
                            initialValue: values.tel,
                        })(
                            <Input type="tel" />
                        )}
                    </Form.Item>

                    <Form.Item label="邮箱">
                        {getFieldDecorator('email', {
                            initialValue: values.email,
                        })(
                            <Input type="email" />
                        )}
                    </Form.Item>

                    <Form.Item label="QQ">
                        {getFieldDecorator('qq', {
                            initialValue: values.qq,
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item label="微信">
                        {getFieldDecorator('wechat', {
                            initialValue: values.wechat,
                        })(
                            <Input />
                        )}
                    </Form.Item>

                </Card>

                <Card title="研究生（硕士）" size="small" bordered={false}>

                    <Form.Item label="学校">
                        {getFieldDecorator('shuoshi_xuexiao', {
                            initialValue: values.shuoshi_xuexiao,
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item label="专业">
                        {getFieldDecorator('shuoshi_zhuanye', {
                            initialValue: values.shuoshi_zhuanye,
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item label="时间">
                        {getFieldDecorator('shuoshi_shijian', {
                            initialValue: values.shuoshi_shijian,
                        })(
                            <DatePicker.RangePicker style={{ width: '100%' }} />
                        )}
                    </Form.Item>

                </Card>

                <Card title="本科（学士）" size="small" bordered={false}>

                    <Form.Item label="学校">
                        {getFieldDecorator('xueshi_xuexiao', {
                            initialValue: values.xueshi_xuexiao,
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item label="专业">
                        {getFieldDecorator('xueshi_zhuanye', {
                            initialValue: values.xueshi_zhuanye,
                        })(
                            <Input />
                        )}
                    </Form.Item>

                    <Form.Item label="时间">
                        {getFieldDecorator('xueshi_shijian', {
                            initialValue: values.xueshi_shijian,
                        })(
                            <DatePicker.RangePicker style={{ width: '100%' }} />
                        )}
                    </Form.Item>

                </Card>
            </Form>
        );
    }
}

export default Form.create({
    onValuesChange(props, changedValues, allValues) {
        props.onValuesChange(changedValues, allValues);
    }
})(BasicForm);