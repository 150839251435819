import React, { useState } from 'react';
import { Input, Form, Radio, DatePicker, Card, Button, Collapse } from 'antd';
import moment from 'moment';
import './DetailForm.css';


function DetailForm(props) {
    const {
        form: { getFieldDecorator },
        values,
        onAddMainBlock,
        onAddSubBlock,
    } = props;

    return (
        <Form className="user-info-form" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
            <div className="btn-wrapper" style={{ marginBottom: 12 }}>
                <Button onClick={onAddMainBlock}>添加一级模块</Button>
            </div>

            {values.map((mainBlock, mainBlockIndex) => (
                <Card
                    key={mainBlock.key}
                    title={(
                        getFieldDecorator(`detail[${mainBlockIndex}].title`, {
                            initialValue: mainBlock.title,
                        })(
                            <Input placeholder="输入一级模块的标题，如：校内经历" style={{ width: 280 }} />
                        )
                    )}
                    // type="inner"
                    size="small"
                    bordered={false}
                    style={{
                        // border: '1px solid black',
                        marginBottom: 16,
                    }}
                    extra={<Button type="link" onClick={() => onAddSubBlock(mainBlockIndex)}>添加二级模块</Button>}
                >
                    {getFieldDecorator(`detail[${mainBlockIndex}].key`, {
                        initialValue: mainBlock.key,
                    })(<Input type="hidden" />)}
                    <Collapse>
                        {mainBlock.children.map((subBlock, subBlockIndex) => (
                            <Collapse.Panel
                                key={subBlock.key}
                                header={(
                                    <Form.Item label="标题">
                                        {getFieldDecorator(`detail[${mainBlockIndex}].children[${subBlockIndex}].title`, {
                                            initialValue: subBlock.title,
                                        })(
                                            <Input onClick={e => e.stopPropagation()} />
                                        )}
                                    </Form.Item>
                                )}
                            >
                                {getFieldDecorator(`detail[${mainBlockIndex}].children[${subBlockIndex}].key`, {
                                    initialValue: subBlock.key,
                                })(<Input type="hidden" />)}

                                <Form.Item label="副标题">
                                    {getFieldDecorator(`detail[${mainBlockIndex}].children[${subBlockIndex}].subTitle`, {})(
                                        <Input />
                                    )}
                                </Form.Item>

                                <Form.Item label="时间">
                                    {getFieldDecorator(`detail[${mainBlockIndex}].children[${subBlockIndex}].dateRange`, {})(
                                        <DatePicker.RangePicker style={{ width: '100%' }} />
                                    )}
                                </Form.Item>

                                <Form.Item label="地点">
                                    {getFieldDecorator(`detail[${mainBlockIndex}].children[${subBlockIndex}].location`, {})(
                                        <Input />
                                    )}
                                </Form.Item>

                                <Form.Item label="类型">
                                    {getFieldDecorator(`detail[${mainBlockIndex}].children[${subBlockIndex}].contentType`, {})(
                                        <Radio.Group>
                                            <Radio.Button value="text">文字</Radio.Button>
                                            <Radio.Button value="list">列表</Radio.Button>
                                            <Radio.Button value="list2">列表 2</Radio.Button>
                                            <Radio.Button value="list3">列表 3</Radio.Button>
                                        </Radio.Group>
                                    )}
                                </Form.Item>

                                {subBlock.contentType === 'text' && (
                                    <Form.Item label="内容">
                                        {getFieldDecorator(`detail[${mainBlockIndex}].children[${subBlockIndex}].text`, {})(
                                            <Input.TextArea
                                                autoSize={{ minRows: 2, maxRows: 6 }}
                                                placeholder="使用回车分段"
                                            />
                                        )}
                                    </Form.Item>
                                )}

                                {subBlock.contentType === 'list' && (
                                    <Form.Item label="第 1 列">
                                        {getFieldDecorator(`detail[${mainBlockIndex}].children[${subBlockIndex}].list`, {})(
                                            <Input.TextArea
                                                autoSize={{ minRows: 2, maxRows: 6 }}
                                                placeholder="使用回车分段"
                                            />
                                        )}
                                    </Form.Item>
                                )}

                                {subBlock.contentType === 'list2' && (
                                    <>
                                        <Form.Item label="第 1 列">
                                            {getFieldDecorator(`detail[${mainBlockIndex}].children[${subBlockIndex}].list`, {})(
                                                <Input.TextArea
                                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                                    placeholder="使用回车分段"
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="第 2 列">
                                            {getFieldDecorator(`detail[${mainBlockIndex}].children[${subBlockIndex}].list2`, {})(
                                                <Input.TextArea
                                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                                    placeholder="使用回车分段"
                                                />
                                            )}
                                        </Form.Item>
                                    </>
                                )}

                                {subBlock.contentType === 'list3' && (
                                    <>
                                        <Form.Item label="第 1 列">
                                            {getFieldDecorator(`detail[${mainBlockIndex}].children[${subBlockIndex}].list`, {})(
                                                <Input.TextArea
                                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                                    placeholder="使用回车分段"
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="第 2 列">
                                            {getFieldDecorator(`detail[${mainBlockIndex}].children[${subBlockIndex}].list2`, {})(
                                                <Input.TextArea
                                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                                    placeholder="使用回车分段"
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="第 3 列">
                                            {getFieldDecorator(`detail[${mainBlockIndex}].children[${subBlockIndex}].list3`, {})(
                                                <Input.TextArea
                                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                                    placeholder="使用回车分段"
                                                />
                                            )}
                                        </Form.Item>
                                    </>
                                )}

                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </Card>
            ))}

        </Form>
    );
}

export default Form.create({
    onValuesChange(props, changedValues, allValues) {
        props.onValuesChange(changedValues, allValues);
    }
})(DetailForm);