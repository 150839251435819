import qs from 'query-string';

function _fetch({ method, path, params = {}, body }) {
    if (Object.keys(params).length) {
        path = path + '?' + qs.stringify(params);
    }

    const options = {
        method,
    }

    if (body) {
        options.body = JSON.stringify(body);
        options.headers = new Headers({
            'Content-Type': 'application/json'
        });
    }

    return fetch(path, options).then(res => res.json());
}

export function sendCode(body) {
    return _fetch({
        method: 'post',
        path: '/api/sendCode',
        body,
    });
}

export function verifyCode(body) {
    return _fetch({
        method: 'post',
        path: '/api/verifyCode',
        body,
    });
}

export function getUserInfo() {
    return _fetch({
        path: '/api/me',
    });
}

export function logout() {
    return _fetch({
        path: '/api/logout',
    });
}

export function saveData(body) {
    return _fetch({
        method: 'post',
        path: '/api/save',
        body,
    });
}