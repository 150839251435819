import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Input, Tabs, Icon, Button, Select, Drawer, ConfigProvider, Modal, Form, message, Menu, Dropdown, Divider } from 'antd';
import BasicForm from './BasicForm';
import DetailForm from './DetailForm';
import templates from './templates';
import { sendCode, verifyCode, getUserInfo, logout, saveData } from './services';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import './App.css';


function MyInput(props) {
  const { label, name, value, onChange } = props;

  function handleChange(e) {
    const { name, value } = e.target;
    onChange(name, value);
  }

  return (
    <div>
      <label>
        <div>{label}：</div>
        <Input
          name={name}
          value={value}
          onChange={handleChange}
          style={{ width: 200 }}
          autoComplete="off"
        />
      </label>
    </div>
  );
}

function TemplateSelect(props) {
  const { label, name, value, onChange } = props;
  function handleChange(value) {
    onChange(name, value);
  }

  return (
    <div>
      <label>
        <div>{label}：</div>
        <Select
          name={name}
          value={value}
          defaultValue="default"
          onChange={handleChange}
          style={{ width: 200 }}
        >
          <Select.Option value="default">默认</Select.Option>
        </Select>
      </label>
    </div>
  );
}

const root = document.createElement('root');

function Frame(props) {
  const Template = templates['default'];
  return ReactDOM.createPortal(
    <Template {...props} />,
    root,
  );
}

function App() {
  const [templateName, setTemplateName] = useState('default');
  const [drawerVisible, setDrawerVisible] = useState([false, false]);
  const [loginModalVisible, setLoginModalVisible] = useState(false);

  const [basicFormValues, setBasicFormValues] = useState({});
  const [detailFormValues, setDetailFormValues] = useState([]);

  const [sendCodeLoading, setSendCodeLoading] = useState(false);
  const [sendCodeTimeout, setSendCodeTimeout] = useState(0);
  const [email, setEmail] = useState();
  const [code, setCode] = useState();

  const [isLogin, setIsLogin] = useState(false);

  const [loginLoading, setLoginLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const iframeRef = useRef();
  const timer = useRef();

  useEffect(() => {
    iframeRef.current.contentDocument.body.appendChild(root);
    getData();
  }, []);

  useEffect(() => {
  }, []);


  function handlePrint() {
    iframeRef.current.contentWindow.print();
  }

  function handleSave() {
    setSaveLoading(true);
    saveData({
      basic: basicFormValues,
      detail: detailFormValues,
    }).then(res => {
      message[res.msgLevel](res.msgContent);
    }).finally(() => {
      setSaveLoading(false);
    });
  }

  function handleBasicFormChange(changedValues, allValues) {
    setBasicFormValues(allValues);
  }

  function handleDetailFormChange(changedValues, allValues) {
    setDetailFormValues(allValues.detail);
    console.log(allValues.detail);
    console.log(JSON.stringify(allValues.detail))
  }


  function handleAddMainBlock() {
    setDetailFormValues([
      ...detailFormValues,
      {
        key: Date.now(),
        children: [{
          key: Date.now(),
        }],
      }
    ]);
  }

  function handleAddSubBlock(mainBlockIndex) {
    const nextValues = [...detailFormValues];
    nextValues[mainBlockIndex].children.push({
      key: Date.now(),
    });
    setDetailFormValues(nextValues);
  }

  function getData() {
    return getUserInfo().then(res => {
      if (res.success) {
        setEmail(res.data.email);
        setIsLogin(true);
        setBasicFormValues(res.data.basic || {});
        setDetailFormValues(res.data.detail || []);
      }
    });
  }

  function handleSendCode() {
    setSendCodeLoading(true);

    sendCode({
      email
    }).then(res => {
      if (res.success) {
        const sendTime = Date.now();
        const leftSecond = Number.parseInt(((sendTime + 9 * 1000) - Date.now()) / 1000);
        setSendCodeTimeout(leftSecond);
        timer.current = setInterval(() => {
          const leftSecond = Number.parseInt(((sendTime + 10 * 1000) - Date.now()) / 1000);
          setSendCodeTimeout(leftSecond);
          if (leftSecond < 1) {
            clearInterval(timer.current);
          }
        }, 1000);
      }

      message[res.msgLevel](res.msgContent);
    }).finally(() => {
      setSendCodeLoading(false);
    });
  }

  function handleLogin() {
    setLoginLoading(true);

    verifyCode({
      email, code
    }).then(async res => {
      if (res.success) {
        await getData();
        setLoginModalVisible(false);
      }

      message[res.msgLevel](res.msgContent);
    }).finally(() => {
      setLoginLoading(false);
    });
  }

  function handleLogout() {
    logout().then(() => {
      window.location.reload();
    });
  }


  return (
    <ConfigProvider locale={zhCN}>
      <div className="container">
        <iframe title="resume render page" ref={iframeRef} />
        <Frame basic={basicFormValues} detail={detailFormValues} />

        <main>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
            <div className="btn-wrapper">
              <Button type="primary" icon="printer" onClick={handlePrint}>打印</Button>
              <Button icon="save" onClick={handleSave} loading={saveLoading}>保存</Button>
            </div>
            {isLogin
              ? (
                <Dropdown overlay={<Menu onClick={handleLogout}><Menu.Item>退出</Menu.Item></Menu>}>
                  <Button>{email}</Button>
                </Dropdown>
              )
              : <Button onClick={() => setLoginModalVisible(true)}>登录</Button>
            }
          </div>

          <Divider />

          <div className="btn-wrapper">
            <Button onClick={() => setDrawerVisible([true, false])}>基础资料</Button>
            <Button onClick={() => setDrawerVisible([false, true])}>其他经历</Button>
          </div>

          {/* <Tabs>
          </Tabs> */}
        </main>

        <Modal
          closable={false}
          visible={loginModalVisible}
          okText="登录"
          onCancel={() => setLoginModalVisible(false)}
          width={380}
          footer={false}
        >
          <Form>
            <Form.Item help="未注册的将自动注册">
              <Input
                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                name="email"
                type="email"
                placeholder="请输入你的邮箱地址"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Input
                prefix={<Icon type="number" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="请输入收到的验证码"
                style={{ width: 200, marginRight: 8 }}
                value={code}
                onChange={e => setCode(e.target.value)}
              />
              <Button
                style={{ width: 124 }}
                onClick={handleSendCode}
                loading={sendCodeLoading}
                disabled={sendCodeTimeout}
              >
                {sendCodeTimeout ? sendCodeTimeout + ' 秒' : '获取验证码'}
              </Button>
            </Form.Item>
            <Button block type="primary" onClick={handleLogin} loading={loginLoading}>登录</Button>
          </Form>
        </Modal>

        <Drawer
          closable={false}
          onClose={() => setDrawerVisible([false, false])}
          visible={drawerVisible[0]}
          width={500}
        >
          <BasicForm
            values={basicFormValues}
            onValuesChange={handleBasicFormChange}
          />
        </Drawer>

        <Drawer
          closable={false}
          onClose={() => setDrawerVisible([false, false])}
          visible={drawerVisible[1]}
          width={500}
        >
          <DetailForm
            values={detailFormValues}
            onValuesChange={handleDetailFormChange}
            onAddMainBlock={handleAddMainBlock}
            onAddSubBlock={handleAddSubBlock}
          />
        </Drawer>
      </div>
    </ConfigProvider>
  );
}


export default App;
