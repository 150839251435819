import React from 'react';
import moment from 'moment';

function getDateRangeString(dateRange) {
    if (dateRange) {
        const start = moment(dateRange[0]);
        const end = moment(dateRange[1]);

        if (start.year() === end.year()) {
            if (start.month() === end.month()) {
                return start.format('YYYY年M月');
            } else {
                return start.format('YYYY年M月') + '-' + end.format('M月');
            }
        } else {
            return start.format('YYYY年M月') + '-' + end.format('YYYY年M月');
        }
    }

    return '';
}

function getList(text) {
    if (text) {
        return text.split(/\n/);
    }
    return [];
}

export default function Default(props) {
    const { basic, detail } = props;
    return (
        <>
            <link rel="stylesheet" href="/templates/default.css" />
            <h1>{basic.name}</h1>
            <span>{basic.birth && moment(basic.birth).format('YYYY-MM-DD')}</span>
            <span>{basic.tel} | {basic.email}</span>

            {detail.map((mainBlock) => (
                <React.Fragment key={mainBlock.key}>
                    <h2>{mainBlock.title}</h2>
                    {mainBlock.children.map((subBlock) => (
                        <React.Fragment key={subBlock.key}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{subBlock.title}</span>
                                <span>{getDateRangeString(subBlock.dateRange)}</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{subBlock.subTitle}</span>
                                <span>{subBlock.location}</span>
                            </div>
                            {subBlock.contentType === 'text' && (
                                <div>
                                    {getList(subBlock.text).map((item, index) => (
                                        <p key={index}>{item}</p>
                                    ))}
                                </div>
                            )}
                            {subBlock.contentType === 'list' && (
                                <div>
                                    <ul>
                                        {getList(subBlock.list).map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {subBlock.contentType === 'list2' && (
                                <div>
                                    <ul>
                                        {getList(subBlock.list).map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                    <ul>
                                        {getList(subBlock.list2).map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {subBlock.contentType === 'list3' && (
                                <div>
                                    <ul>
                                        {getList(subBlock.list).map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                    <ul>
                                        {getList(subBlock.list2).map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                    <ul>
                                        {getList(subBlock.list3).map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ))}
        </>
    );
}